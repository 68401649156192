// src/PinInputModal.js
import { useState, useEffect } from 'react';
import validator from "validator";
import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  Icon,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Heading,
  PinInput,
  PinInputField,
  ModalHeader,
  VStack,
  Grid,
  GridItem,
  useColorModeValue,
  Text,
  useToast,
} from '@chakra-ui/react';

import { ApiHeader } from '../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../_helpers/Auth';

import InvoiceBg from "../../../assets/img/InvoiceBg.png";
import { VSeparator, HSeparator } from "../../../components/separator/Separator";
import PhoneNumberInput from '../../../components/phoneNumberInput/phoneNumberInput';
import Card from "../../../components/card/Card.js";
import { MdEmail } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai';
import { AiFillCreditCard } from 'react-icons/ai';
import { FcGoogle } from "react-icons/fc";

import { useGoogleLogin } from '@react-oauth/google';

const PinInputModal = ({ 
  isOpen, 
  onClose, 
  openWalletConnect, 
  showWalletConnect, 
  headerText = 'Authenticate with paypangea' 
}) => {

  const toast = useToast();
  const [pin, setPin] = useState('');

  const [stage, setStage] = useState(0);
  const [username, setUsername] = useState('');
  const [loginType, setLoginType] = useState('');

  const [isSupported, setIsSupported] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    setLoginType('');
    setStage(0);
  },[])

  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");

  const selectLoginType = (logintype) => {
    setLoginType(logintype);
    setStage(1);
  }

  const verifyGoogleCode = (code) => {
    if (code === '') {
      toast({
        title: 'Error.',
        description: "Credentials can not be empty",
        status: 'error',
      })
      return;
    }
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'auth/login-google-code', {
      method: 'POST',
      body: JSON.stringify({
        "code" : code
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        SetJwtAuth(data.tokens.access.token);
        setStage(0);
        onClose(data.newUser)
      } else {
        console.log(data.message)
        setStage(0);
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      console.log(tokenResponse.code);

      verifyGoogleCode(tokenResponse.code)
      
    },
    flow: 'auth-code',
    onError: (error) => {
      toast({
        title: 'Error.',
        description: "Error, please try again",
        status: 'error',
      })
    }
  });

  const loginSelection = () => {
    return (
    <>
        <Text fontSize="md" mt="10px" fontWeight='regular'>
            How do you want to authenticate?
        </Text>
        <Button 
            onClick={()=>selectLoginType('email')} 
            leftIcon={<Icon as={MdEmail} />}
            variant='brand' 
            mt="20px" 
            size="lg" 
            w="100%">
            Email
        </Button>
        
        <Button 
            onClick={()=>selectLoginType('phone')} 
            leftIcon={<Icon as={AiFillPhone} />}
            variant='brand' 
            mt="20px" 
            size="lg" 
            w="100%">
            Phone
        </Button>

        <Flex align='center' mt='25px' mb='0px'>
          <HSeparator />
        </Flex>

        <Button 
          onClick={()=>googleLogin()} 
          leftIcon={<Icon as={FcGoogle} />}
          variant='white'
          mt="20px" 
          size="lg" 
          w="100%">
          Continue with Google
        </Button>

        {showWalletConnect && (
          <>
            <Flex align='center' mt='25px' mb='25px'>
              <HSeparator />
              <Text color={textColor} mx='14px'>
                or
              </Text>
              <HSeparator />
            </Flex>
            
            <Button 
                onClick={()=>{
                  openWalletConnect();
                }} 
                leftIcon={<Icon as={AiFillCreditCard} />}
                variant='brand' 
                size="lg" 
                w="100%">
                Wallet Connect
            </Button>
          </>
        )}
        
    </>
    )
  }  

  const handleBack = () => {
    setStage(stage-1);
  };

  const getLoginCode = () => {
    if (loginType === 'email') {
      if (validator.isEmail(username)) {
        loginEmail();
      } else {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Email is not valid",
          status: 'error',
        })
      }
    } else if (loginType === 'phone') {
      if (validator.isMobilePhone(username, 'any', { strictMode: true })) {
        if (isSupported) {
          loginPhone();
        } else {
          setIsLoading(false);
          toast({
            title: 'Error.',
            description: "Phone login not available in selected country.",
            status: 'error',
          })
        }
      } else if (validator.isMobilePhone(username, 'any', { strictMode: false })) {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Phone number must be in interantional format with + and country code",
          status: 'error',
        })
      } else {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Not recognised phone number",
          status: 'error',
        })
      }
    } else {
        toast({
            title: 'Error.',
            description: 'Login only possible with email or phone',
            status: 'error',
        })
    }
  }
  

  const [verificationToken, setVerificationToken] = useState('');

  const loginEmail = () => {
    fetch(process.env.REACT_APP_API_URL+'auth/login-email', {
      method: 'POST',
      body: JSON.stringify({
        "email" : username,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.tokens) {
        setVerificationToken(data.tokens)
        setStage(2);
        // navigate(`/auth/verification?m=${btoa(username)}&t=${btoa(data.tokens)}`)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  const loginPhone = () => {
    fetch(process.env.REACT_APP_API_URL+'auth/login-phone', {
      method: 'POST',
      body: JSON.stringify({
        "phone" : username,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.tokens) {
        setVerificationToken(data.tokens)
        setStage(2);
        // SetJwtAuth(data.token);
        // navigate('/admin/home')
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  const loginFirstStep = () => {
    return (
    <>
        <FormControl mt="20px" >
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Enter your {loginType}<Text color={brandStars}>*</Text>
            </FormLabel>
            {loginType === 'email' && (
              <Input
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder={loginType}
                mb='24px'
                fontWeight='500'
                size='lg'
                onChange={(e) => setUsername(e.target.value)}
              />
            )}
            {loginType === 'phone' && (
              <PhoneNumberInput
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder={loginType}
                mb='24px'
                fontWeight='500'
                size='lg'
                setSupported={(e) => setIsSupported(e)}
                onChange={(e) => {
                  setUsername(e.replace(/\s+/g, ''))
                }}
              />
            )}

            <Flex w="100%" direction='row' gap={2}>
                <Button onClick={handleBack} 
                    variant='outline' 
                    size="lg" 
                    w="100%">
                    Back
                </Button>
                <Button onClick={getLoginCode} 
                    variant='brand' 
                    size="lg" 
                    isLoading={isLoading}
                    w="100%">
                    Get code
                </Button>
            </Flex>
        </FormControl>
    </>
    )
  }


  const handleComplete = (value) => {
    setPin(value)
  };

  const verifyPasscode = (event) => {
    event.preventDefault();
    if (pin === '') {
      toast({
        title: 'Error.',
        description: "Please enter pin",
        status: 'error',
      })
      return;
    }
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'auth/login-code', {
      method: 'POST',
      body: JSON.stringify({
        "code" : pin,
        "token" : verificationToken
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        SetJwtAuth(data.tokens.access.token);
        setStage(0);
        onClose(data.newUser)
      } else {
        console.log(data.message)
        setStage(0);
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        setStage(0);
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  const loginVerification = () => {
    return (
    <>
        <Heading
            color={textColor}
            fontSize='36px'
            mb='16px'
            mx={{ base: "auto", lg: "unset" }}
            textAlign={{ base: "center", lg: "left" }}>
            Verification
        </Heading>
        <Text
            color='gray.400'
            fontSize='md'
            maxW={{ base: "95%", md: "100%" }}
            mx={{ base: "auto", lg: "unset" }}
            textAlign={{ base: "center", lg: "left" }}>
            Enter the code we send to your {loginType} to unlock your account.
        </Text>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "395px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <Flex justify='center'>
              <PinInput mx='auto' onComplete={handleComplete}>
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                  me='10px'
                />
                <PinInputField
                  fontSize='36px'
                  color={textColor}
                  borderRadius='16px'
                  borderColor={borderColor}
                  h={{ base: "63px", md: "95px" }}
                  w={{ base: "63px", md: "95px" }}
                />
              </PinInput>
            </Flex>

            <Button
              fontSize='14px'
              variant='brand'
              borderRadius='16px'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              isLoading={isLoading}
              onClick={verifyPasscode}
              mt='12px'>
              Unlock
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text
              color={textColorDetails}
              fontWeight='400'
              fontSize='14px'
              mx={{ base: "auto", lg: "unset" }}
              textAlign={{ base: "center", lg: "left" }}>
              Haven't received it?<br></br>
              We've send the code to <span style={{color: textColorBrand}}>{username}</span>.<br></br>
              {loginType === 'email' && (
                <>
                Please check your spam folder if it's not in your inbox.<br></br>
                If you do not find it in spam folder, please check the spelling of your email address.<br></br>
                </>
              )}
              {loginType === 'phone' && (
                <>
                If you did not receive the code, please check if your phone is mobile phone and has country code and (+) included.<br></br>
                </>
              )}
            </Text>
          </Flex>
        </Flex>
    </>
    )
  }

  return (
    <Modal 
        isCentered 
        motionPreset='slideInBottom'
        isOpen={isOpen} 
        onClose={console.log('close')} 
        size="xs">
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='8px'/>
        <ModalContent>

            <Card
                backgroundImage={InvoiceBg}
                backgroundRepeat='no-repeat'
                bgSize='cover'
                bgPosition='10%'
                borderRadius="5px 5px 0 0" 
                p="20px"
                pb="20px">
              
                <Text fontSize="xl" mt="0px" fontWeight='bold'>
                  {headerText} 
                </Text>
            </Card>

            <ModalBody p={'10px 20px 20px'} textAlign="center">
                {stage === 0 && loginSelection()}
                {stage === 1 && loginFirstStep()}
                {stage === 2 && loginVerification()}
            </ModalBody>
        </ModalContent>
    </Modal>
  );
};

export default PinInputModal;
